@charset "utf-8";

/**************************************************************
*
* テーブル基本スタイル
*
**************************************************************/
table {}
table th,
table td {}
table th {}
table td {}

/**************************************************************
*
* インプットフォーム基本スタイル
*
**************************************************************/
/*************************************
* インプット - テキスト
*************************************/
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
  width: 100%;
  
  border: #3ec8b7 1px solid;
  
  padding: 15px;
  
  transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
.form_type1 input[type="text"],
.form_type1 input[type="email"],
.form_type1 input[type="tel"],
.form_type1 input[type="url"],
.form_type1 input[type="password"] {
  max-width: 80%;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: #fefeff;
  background-color: #ecfffd;
  outline: none;
}

input:placeholder-shown { color: #909090;}
input::-webkit-input-placeholder { color: #909090;}
input:-moz-placeholder { color: #909090;}
input::-moz-placeholder { color: #909090;}
input:-ms-input-placeholder { color: #909090;}

@media screen and (max-width: 768px) {

}
@media screen and (max-width: 599px) {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="url"],
  input[type="password"],
  textarea {
    padding: 10px;
  }
}
/*************************************
* インプット - テキストエリア
*************************************/
textarea {
  width: 100%;
  min-height: 170px;
  resize: vertical;

  line-height: 1.65;
}
textarea.large {
  height: 300px;
}
textarea.middle {
  height: 200px;
}
textarea.small {
  height: 100px;
}
/*************************************
* インプット - ボタン
*************************************/
form button[type="submit"],
form button[type="button"]{
  margin: auto;
  margin-bottom: 20px;
}
button.disabled {
  filter: grayscale(100%);
  
  pointer-events: none;
  opacity: 0.6;
}
button.ui-datepicker-trigger {
	width: 50px;
}
/*************************************
* インプット - セレクト
*************************************/
select {
  border: #3ec8b7 1px solid;
  border-radius: 0px;

  padding: 15px;
  padding-right: 35px;

  outline: none;

  width: 100%;
}
.select-wrap{
  position:relative;
  overflow:hidden;
  display:inline-block;
  vertical-align: middle;

  margin-right: 10px;

  background-color:#ffffff;
  border:1px solid #3ec8b7;
}
.select-wrap select{
  appearance:none;
  position:relative;
  z-index:2;
  display:block;

  background:transparent;
  border:0;
  outline:none;

  line-height:1.5;
}
.entypo-down-open-mini:before{
  content:'';
  font-family:'entypo',sans-serif;
  position:absolute;
  z-index:1;
  top: 0;
  bottom: 0;
  right: 10px;

  margin: auto;

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 9px 0 9px;
  border-color: #3ec8b7 transparent transparent transparent;

  line-height:1;
  color:#333;
}
@media screen and (max-width: 599px) {
  select {
    width: 100%;
  }
}
/*************************************
* インプット - チェックボックス
*************************************/
.check_item {
  display: block;

  margin-bottom: 16px;
}
.recruit.subpage .check_item {
  margin-bottom: 0;
}
.check_item label {
  margin-right: 20px;
}
input[type="checkbox"] {
  opacity: 0;
  width: 5px;
}
input[type="checkbox"] + .check {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: #959595 1px solid;
  border-radius: 2px;
  position: relative;
  top: 4px;
  margin-right: 8px;
	
  background-color: #fff;
}
input[type="checkbox"] + .check::after {
  content: '';
  position: absolute;
  top: -9px;
  bottom: 0;
  left: 5px;
  display: block;
  /* margin-top: -7px; */
  margin: auto;
  width: 10px;
  height: 20px;
  border-right: 4px solid #008088;
  border-bottom: 4px solid #008088;
  opacity: 0;
  transform: rotate(45deg);
  
  transition: opacity 100ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
input[type="checkbox"]:checked + .check::after {
  opacity: 1;
}
/*************************************
* インプット - ラジオボタン
*************************************/
.radio_box label {
  display: block;
  float: left;
  margin-right: 25px;
}
input[type="radio"] {
  opacity: 0;
  width: 5px;
}
input[type="radio"] + .radio {
  width: 16px;
  height: 16px;
  display: inline-block;
  border-radius: 50%;
  border: #3ec8b7 1px solid;
  position: relative;

  vertical-align: -0.15em;

  margin-right: 5px;
}
input[type="radio"] + .radio::after {
  content: "";
  width: 70%;
  height: 70%;
  display: inline-block;
  
  margin: auto;
  
  background-color: #008088;
  border-radius: 50%;
  opacity: 0;
  
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  transition: opacity 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
}
input[type="radio"]:checked + .radio::after {
  opacity: 1;
}

/**************************************************************
*
* テンプレートクラス
*
**************************************************************/
.relation_item {
  display: none;
}
.form_notes {
  margin-left: 10px;
  font-size: 0.8em;
}
.asterisk {
  padding: 1px 3px 2px;
  margin-left: 4px;

  background-color: #008088;
  border-radius: 5px;
  
  color: #fff;
  font-size: 1rem;
  font-weight: normal;
  vertical-align: 0.13em;
}
.error{
  display: block;
  color: red;
}